.toastify_custom{
  font-size: 15px!important;
  div{
    padding: 0;
  }
  .Toastify__toast-container{
    top: 85px!important;

  }
  .Toastify__toast{
      padding: 10px;
  }
  .Toastify__close-button{
    display: none;
  }
}