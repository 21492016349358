@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext);
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block; }

b,
strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th,
td,
caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before,
q:after {
  content: "";
  content: none; }

sub,
sup,
small {
  font-size: 75%; }

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after,
.clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ""; }

body {
  color: #1a202c;
  box-sizing: border-box;
  background: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-user-select: none;
          user-select: none; }

.no_search_items {
  font-size: 14px;
  line-height: 21px;
  color: #1a202c;
  padding-bottom: 5px;
  display: inline-block; }

.page_wrapper {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  padding: 0 0 0 18px;
  background: #fafafa;
  height: 100%; }

.transitioned_block {
  height: 100%; }

.page {
  height: calc(100vh - 72px);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding-top: 72px; }

.wrapper {
  height: 100%; }

.controls_wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(48, 49, 51, 0.12);
  border-radius: 8px;
  min-width: 204px;
  max-width: calc(100vw - 40px);
  height: -webkit-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  z-index: 100000 !important;
  right: 22px;
  bottom: 20px;
  top: 78px;
  padding: 16px 14px;
  box-sizing: border-box; }
  .controls_wrapper-sales {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .controls_wrapper-sales .sales {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #1a202c; }
    .controls_wrapper-sales .prices {
      color: #4a5568;
      opacity: 0.5;
      font-size: 12px;
      line-height: 14px; }
  .controls_wrapper-days {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4a5568;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #edf0f4; }
    .controls_wrapper-days > div .digits {
      color: #1a202c;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      margin-right: 3px; }
    .controls_wrapper-days > div > p {
      margin-top: 4px; }
  .controls_wrapper-pricePicker {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    margin-top: 6px;
    margin-bottom: 16px; }
    .controls_wrapper-pricePicker p {
      color: #4A5568;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; }
    .controls_wrapper-pricePicker-wrapper {
      width: 100%;
      display: flex;
      padding: 2px;
      grid-gap: 4px;
      gap: 4px;
      border-radius: 8px;
      background: #F3F5F6;
      position: relative; }
      .controls_wrapper-pricePicker-wrapper .activeIndicator {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        height: calc(100% - 4px);
        width: calc(50% - 4px);
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
        transition: all 150ms; }
      .controls_wrapper-pricePicker-wrapper button {
        width: calc(50% - 2px);
        color: #4A5568;
        font-family: "Roboto", sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        padding: 5px;
        z-index: 5; }
        .controls_wrapper-pricePicker-wrapper button.active {
          color: #1A202C; }
          .controls_wrapper-pricePicker-wrapper button.active + .activeIndicator {
            -webkit-transform: translateX(calc(100% + 4px));
                    transform: translateX(calc(100% + 4px)); }
  .controls_wrapper > a {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-decoration: none; }
    .controls_wrapper > a > img {
      margin-right: 10px; }
    .controls_wrapper > a > p {
      color: #4a5568;
      font-size: 13px;
      line-height: 15px; }
  .controls_wrapper .quit {
    display: flex;
    align-items: center; }
    .controls_wrapper .quit > img {
      margin-right: 10px; }
    .controls_wrapper .quit > p {
      color: #4a5568;
      font-size: 13px;
      line-height: 15px; }

.MuiSnackbar-anchorOriginTopRight {
  top: 80px !important; }
  .MuiSnackbar-anchorOriginTopRight .MuiAlert-root {
    display: flex;
    align-items: center; }
  .MuiSnackbar-anchorOriginTopRight .MuiAlert-action {
    display: none; }

.good_hover {
  transition: all ease 0.3s;
  /* &:hover {
        opacity: 0.5;
    } */ }

a,
button {
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  font-family: "Roboto", sans-serif; }

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

@media only screen and (max-width: 1279px) {
  .page {
    padding-top: 72px;
    height: calc(100vh - 72px); } }

/* .pulse {
    &:hover {
        animation: pulse 2s infinite;
    }
}

@keyframes pulse {
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    0% {
        opacity: 1;
    }
}
 */

.default_button_wrapper .default_button {
  text-align: center;
  display: inline-flex;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
  height: 52px;
  text-transform: initial;
  min-width: 100%;
  width: 100%;
  border-radius: 8px;
  white-space: nowrap;
  transition: all 0.3s linear;
  box-shadow: none; }
  .default_button_wrapper .default_button:disabled {
    opacity: 0.5;
    box-shadow: none; }
  .default_button_wrapper .default_button.default_button_contained {
    background-color: #1CBB32;
    color: #ffffff;
    border: 1px solid #1CBB32;
    font-size: 20px; }
  .default_button_wrapper .default_button.default_button_contained_grey, .default_button_wrapper .default_button.contained_grey {
    background-color: #E3E8EB;
    color: #4A5568;
    font-size: 20px;
    border: 1px solid #E3E8EB; }
  .default_button_wrapper .default_button.default_button_outlined {
    color: rgba(2, 0, 24, 0.5);
    border: 1px solid transparent; }
    .default_button_wrapper .default_button.default_button_outlined.cancel_btn {
      background: #eff3f3;
      color: #7f8fa4; }
    .default_button_wrapper .default_button.default_button_outlined.blue_btn {
      border: 1px solid #0253b3;
      color: #0253b3; }
    .default_button_wrapper .default_button.default_button_outlined.delete_btn {
      border: 1px solid #d0021b;
      color: #d0021b; }
  .default_button_wrapper .default_button div {
    color: #ffffff; }

.default_button_wrapper .full_btn_width {
  width: 100%; }

button:disabled {
  background: grey;
  opacity: 0.65;
  cursor: not-allowed;
  -webkit-animation: none;
          animation: none; }

@media only screen and (max-width: 1365px) {
  .default_button_wrapper .default_button {
    font-size: 10px; } }

.default_dialog_root .paper_custom_dialog {
  border-radius: 6px;
  min-width: 460px;
  width: 460px;
  box-shadow: none; }
  @media only screen and (max-height: 300px) {
    .default_dialog_root .paper_custom_dialog {
      height: 100%;
      margin: 0;
      justify-content: flex-start;
      padding: 32px 32px;
      overflow-y: auto;
      border-radius: 0; }
      .default_dialog_root .paper_custom_dialog .dialog_wrapper {
        align-items: flex-start; } }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 26px;
  background: transparent;
  transition: all 0.3s linear; }
  .default_dialog_root .dialog_close_button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.dialog_wrapper {
  display: flex;
  position: relative;
  background-color: #ffffff;
  overflow: auto; }
  .dialog_wrapper form {
    width: 100%; }
  .dialog_wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .dialog_wrapper::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #edf2f8; }
  .dialog_wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: grey; }
  .dialog_wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: grey; }

.sidebar_wrapper {
  /* width: 473px;
	min-width: 473px; */
  height: 100%;
  box-shadow: 0px -2px 16px rgba(48, 49, 51, 0.12);
  background: #ffffff;
  overflow-y: auto; }
  .sidebar_wrapper .logo-wrapper {
    padding: 24px 11px 0 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .sidebar_wrapper .logo-wrapper > img {
      cursor: pointer; }
  .sidebar_wrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px; }
  .sidebar_wrapper::-webkit-scrollbar-track {
    -webkit-border-radius: 60px;
    background: #f2f2f3; }
  .sidebar_wrapper::-webkit-scrollbar-thumb {
    border-radius: 60px;
    background: rgba(74, 85, 104, 0.25); }
  .sidebar_wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(74, 85, 104, 0.25); }

.tree-item-content {
  display: flex;
  align-items: center; }
  .tree-item-content > img {
    width: 24px;
    height: 24px;
    margin-right: 8px; }

.cat_sidebar {
  max-width: 473px;
  margin-right: 0 !important;
  border-right: 1px solid rgba(203, 213, 224, 0.5);
  overflow: auto;
  height: calc(100vh - 68px); }
  .cat_sidebar::-webkit-scrollbar {
    width: 0px;
    height: 0px; }
  .cat_sidebar::-webkit-scrollbar-track {
    -webkit-border-radius: 60px;
    background: #f2f2f3; }
  .cat_sidebar::-webkit-scrollbar-thumb {
    border-radius: 60px;
    background: rgba(74, 85, 104, 0.25); }
  .cat_sidebar::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(74, 85, 104, 0.25); }
  .cat_sidebar.MuiTreeView-root {
    margin-left: auto;
    background: #ffffff; }
  .cat_sidebar .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
  .cat_sidebar .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:active,
  .cat_sidebar .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:focus,
  .cat_sidebar .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover {
    background-color: #fff7f7 !important; }
  .cat_sidebar .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: unset !important; }
  .cat_sidebar .MuiTreeItem-content {
    transition: 0.3s; }

.cat_item .MuiTypography-body1 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #1a202c; }

.cat_item .MuiTreeItem-content {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid rgba(203, 213, 224, 0.5); }

.cat_item .MuiTreeItem-label {
  margin-left: 20px;
  padding-left: 0;
  padding-top: 16px;
  padding-bottom: 16px; }
  .cat_item .MuiTreeItem-label:hover {
    background-color: unset; }
  .cat_item .MuiTreeItem-label:focus {
    background-color: unset; }

.cat_item .MuiTreeItem-iconContainer {
  margin-right: 10px;
  width: 36px; }

.cat_item.Mui-expanded > .MuiTreeItem-content {
  position: relative; }

.cat_item .MuiTreeItem-group {
  margin-left: 0;
  background-color: #ffffff; }
  .cat_item .MuiTreeItem-group .MuiTreeItem-label {
    font-size: 14px;
    line-height: 20px;
    color: #4a5568; }

.cat_item.required-nodes .tree-item-content {
  color: #ff010b;
  font-weight: 500; }

.current_cat {
  background-color: #fff7f7;
  position: relative; }
  .current_cat .MuiTreeItem-content:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100% !important;
    background: #ff010b; }
  .current_cat > div > .MuiTreeItem-label {
    position: relative;
    /* &:before {
				position: absolute;
				content: url("../../assets/image/cat-check.svg");
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 32px;
				height: 32px;
			} */ }

@media only screen and (max-width: 1279px) {
  .current_cat .MuiTreeItem-content:before {
    height: 50px; }
  .cat_item .MuiTypography-body1 {
    font-size: 14px;
    line-height: 20px; }
  .cat_item .MuiTreeItem-iconContainer img {
    background-size: 100% 100%; } }

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
  .spinner-wrapper > img {
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.header_wrapper {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.08);
  z-index: 999;
  height: 72px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  top: 0; }
  .header_wrapper .sidebar-wrapper {
    position: absolute;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 244px;
    display: none; }
    .header_wrapper .sidebar-wrapper.open {
      display: block; }
  .header_wrapper .menu {
    display: flex; }
    .header_wrapper .menu .avatar {
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .header_wrapper .menu .avatar img {
        width: 52px;
        height: 52px;
        background-size: 100% 100%;
        border-radius: 50%; }
    .header_wrapper .menu .menu_info {
      display: flex;
      flex-flow: column;
      justify-content: center; }
      .header_wrapper .menu .menu_info span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        padding-bottom: 4px; }
      .header_wrapper .menu .menu_info p {
        font-size: 14px;
        line-height: 16px;
        opacity: 0.75; }
    .header_wrapper .menu + .logout-backdrop {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 999; }
  .header_wrapper .head_block {
    display: flex;
    align-items: center;
    width: 100%; }
    .header_wrapper .head_block .burger-img {
      margin-right: 14px;
      cursor: pointer; }
    .header_wrapper .head_block .logo {
      margin-right: 26px; }
    .header_wrapper .head_block .search_wrapper {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%; }
      .header_wrapper .head_block .search_wrapper > span {
        position: absolute;
        top: calc(50% - 9.5px);
        left: 22px;
        z-index: 3; }
        .header_wrapper .head_block .search_wrapper > span.error_text {
          position: absolute;
          top: 100%;
          left: 0; }
      .header_wrapper .head_block .search_wrapper img {
        position: relative;
        z-index: 1; }
      .header_wrapper .head_block .search_wrapper input {
        padding: 0 20px 0 52px;
        height: 52px;
        border: 1px solid #cfd8e2;
        box-sizing: border-box;
        color: #4a5568;
        font-family: "Roboto", sans-serif;
        border-radius: 8px;
        font-size: 16px;
        width: 100%;
        line-height: 19px;
        z-index: 2; }
        .header_wrapper .head_block .search_wrapper input::-webkit-input-placeholder {
          opacity: 0.6; }
        .header_wrapper .head_block .search_wrapper input::placeholder {
          opacity: 0.6; }
      .header_wrapper .head_block .search_wrapper button {
        width: 120px;
        height: 52px;
        background: #1cbb32;
        box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
        border-radius: 0px 8px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        z-index: 2;
        cursor: pointer; }
      .header_wrapper .head_block .search_wrapper .search_backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2; }
        .header_wrapper .head_block .search_wrapper .search_backdrop .search_info {
          width: 600px;
          top: 78px;
          max-height: 617px;
          z-index: 9999;
          position: absolute;
          left: 325px;
          background: #ffffff;
          box-shadow: -2px 0px 6px rgba(48, 49, 51, 0.16), 0px 8px 16px rgba(48, 49, 51, 0.12);
          border-radius: 8px;
          padding: 18px 24px;
          box-sizing: border-box; }
          .header_wrapper .head_block .search_wrapper .search_backdrop .search_info > button {
            margin-top: 20px;
            width: 100%;
            height: 47px;
            background: #1cbb32;
            box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            cursor: pointer; }
          .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll {
            overflow-x: auto;
            max-height: 514px; }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll::-webkit-scrollbar {
              width: 10px;
              height: 10px; }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll::-webkit-scrollbar-track {
              -webkit-border-radius: 2px;
              background: #fafafa; }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background: rgba(74, 85, 104, 0.25); }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll::-webkit-scrollbar-thumb:window-inactive {
              background: rgba(74, 85, 104, 0.25); }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .info {
              border-top: 1px solid rgba(203, 213, 224, 0.5);
              padding-top: 16px;
              display: flex;
              flex-flow: column; }
              .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .info p {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                padding-bottom: 14px;
                display: flex; }
              .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .info span {
                font-size: 14px;
                line-height: 21px;
                padding-bottom: 14px;
                display: flex; }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .info:last-of-type span:last-of-type {
              padding: 0; }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .title_search {
              padding-bottom: 14px;
              font-weight: 500;
              font-size: 16px;
              display: flex;
              line-height: 19px; }
            .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div {
              padding-bottom: 14px;
              display: flex;
              align-items: center; }
              .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .img {
                margin-right: 16px; }
                .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .img img {
                  width: 52px;
                  height: 52px;
                  background-size: 100% 100%; }
              .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .name {
                font-size: 14px;
                line-height: 21px;
                display: flex;
                padding-bottom: 6px; }
              .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .wrapper_info {
                display: flex;
                align-items: center; }
                .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .wrapper_info .price {
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 19px; }
                  .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .wrapper_info .price.stock {
                    color: #ff010b;
                    display: flex;
                    align-items: flex-end; }
                    .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .wrapper_info .price.stock p {
                      margin-left: 16px;
                      font-size: 16px;
                      line-height: 19px;
                      color: rgba(74, 85, 104, 0.5);
                      position: relative; }
                      .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .wrapper_info .price.stock p:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: calc(50% - 0.5px);
                        height: 1px;
                        background: #ff010b;
                        width: 100%; }
                .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll .product_search > div .prod_info .wrapper_info .art {
                  margin-right: 18px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  padding: 6px 14px;
                  background: rgba(203, 213, 224, 0.25);
                  border-radius: 8px; }

.panel_info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px; }
  .panel_info a {
    display: flex;
    background: transparent;
    width: 40px;
    opacity: 0.75;
    justify-content: center;
    align-items: center; }
    .panel_info a .red {
      display: none; }
    .panel_info a svg {
      width: 24px;
      height: 24px; }
      .panel_info a svg path {
        fill: #1a202c; }
  .panel_info a:nth-child(2) {
    margin: 0 10px; }
  .panel_info .active span {
    color: #ff010b; }
  .panel_info .active .grey {
    display: none; }
  .panel_info .active .red {
    display: flex; }
  .panel_info .active svg {
    width: 24px;
    height: 24px; }
    .panel_info .active svg path {
      fill: #ff010b; }

.header-cart {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  background: #f3f5f6;
  align-items: center;
  margin-left: 12px;
  padding: 20px 10px 22px 14px;
  min-width: 140px; }
  .header-cart-img {
    margin-right: 12px;
    position: relative;
    /* > div {
            position: absolute;
            top: -7px;
            right: -7px;
            color: #ffffff;
            background: #1cbb32;
            width: 18px;
            height: 18px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.95;
            font-weight: 500;
            font-size: 11px;
            line-height: 11px;
        } */ }
    .header-cart-img > div {
      position: absolute;
      top: -7px;
      right: -7px;
      color: #ffffff;
      background: rgba(28, 187, 50, 0.7);
      min-width: 12px;
      height: 18px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.95;
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      padding: 0px 3px; }
  .header-cart-other .total {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.75;
    color: #2d3d45; }
  .header-cart-other .price {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #1e282c; }

@media only screen and (max-width: 1279px) {
  .panel_info {
    margin-left: 0; }
  .header_wrapper {
    height: 72px; }
    .header_wrapper .menu {
      justify-content: flex-end;
      min-width: 170px; }
      .header_wrapper .menu .avatar img {
        background-size: 100% 100%;
        width: 32px;
        height: 32px; }
      .header_wrapper .menu .menu_info span {
        font-size: 13px;
        line-height: 15px;
        padding-bottom: 3px; }
      .header_wrapper .menu .menu_info p {
        font-size: 12px;
        line-height: 14px; }
    .header_wrapper .head_block .logo a img {
      background-size: 100% 100%;
      width: 100px; }
    .header_wrapper .head_block .search_wrapper {
      margin-right: 20px;
      justify-content: space-between; }
      .header_wrapper .head_block .search_wrapper > input {
        height: 45px;
        border-radius: 8px;
        transition: all 0.3s linear;
        background: transparent;
        width: 100%;
        padding: 0 15px 0 40px; }
        .header_wrapper .head_block .search_wrapper > input:focus {
          color: #4a5568; }
      .header_wrapper .head_block .search_wrapper > span {
        z-index: -1;
        left: 15px;
        top: calc(50% - 10px);
        display: flex; }
        .header_wrapper .head_block .search_wrapper > span img {
          background-size: 100% 100%;
          width: 20px;
          height: 20px; }
      .header_wrapper .head_block .search_wrapper > button {
        display: none;
        width: 100px;
        height: 45px;
        font-size: 15px;
        line-height: 17px; }
      .header_wrapper .head_block .search_wrapper .search_backdrop .search_info {
        left: 215px;
        max-height: 500px;
        top: 55px; }
        .header_wrapper .head_block .search_wrapper .search_backdrop .search_info .wrapper_scroll {
          max-height: 300px; } }

.button_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; }
  .button_container > span {
    width: 100%;
    margin-top: 15px;
    color: red;
    font-size: 12px;
    font-weight: 400;
    text-align: left; }
  .button_container > div {
    width: 45%; }
    .button_container > div button {
      position: relative; }
      .button_container > div button div.MuiCircularProgress-colorSecondary {
        color: #ffffff;
        position: absolute;
        left: 30px;
        top: 14px;
        height: 20px !important;
        width: 20px !important; }
      .button_container > div button.MuiButton-contained {
        box-shadow: none; }
        .button_container > div button.MuiButton-contained.disline {
          background-color: #fff;
          color: #4a5568;
          border-width: 2px; }
        .button_container > div button.MuiButton-contained.Mui-disabled:hover {
          background-color: #1cbb32;
          opacity: 0.5; }

.custom_dialog .paper_custom_dialog {
  width: 900px;
  max-width: 900px; }

.custom_dialog .total_all {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-size: 20px; }

.custom_dialog .dialog_wrapper {
  position: relative;
  flex-direction: column;
  align-items: flex-end; }

.custom_dialog .dialog_close_button {
  position: sticky;
  right: 10px;
  left: auto;
  top: 10px;
  margin-top: -20px; }

.custom_dialog .dialog_cart {
  box-sizing: border-box;
  width: 100%;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-flow: column;
  padding: 32px; }
  .custom_dialog .dialog_cart .client_content {
    width: 460px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    padding: 36px 40px;
    box-sizing: border-box; }
    .custom_dialog .dialog_cart .client_content.waiting {
      position: relative; }
      .custom_dialog .dialog_cart .client_content.waiting > *:not(.loader) {
        opacity: 0.3; }
      .custom_dialog .dialog_cart .client_content.waiting .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center; }
        .custom_dialog .dialog_cart .client_content.waiting .loader .MuiCircularProgress-root {
          color: #ff4048; }
  .custom_dialog .dialog_cart .title {
    padding-bottom: 36px;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: center; }
  .custom_dialog .dialog_cart .descriptions {
    padding-bottom: 36px;
    margin-top: -20px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #4a5568; }
  .custom_dialog .dialog_cart .with_cart {
    align-items: flex-start;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 0 20px 0;
    width: 100%; }
    .custom_dialog .dialog_cart .with_cart .client_content.client_info {
      width: 100%; }
      .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart {
        width: 100%; }
        .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart > div {
          border-bottom: none; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart > div .name {
            padding: 0; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart > div .clear_cart {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #fe1a23; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart > div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center; }
        .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart :not(:last-child) {
          margin-bottom: 20px; }
        .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart .empty p {
          margin-left: 24px;
          padding-bottom: 24px;
          opacity: 0.7;
          font-size: 12px; }
      .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 0; }
        .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: flex-start;
          padding: 15px 0; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container:not(:last-child) {
            border-bottom: 1px solid #e1ecf1; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container > .image-wrapper {
            width: 64px;
            padding-right: 16px; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container > .image-wrapper img {
              width: 64px;
              height: 64px;
              object-fit: contain; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 80px); }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.header {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.header > .heading {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #1a202c; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.header > .delete {
              margin: -4px 0 0 15px; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 12px 0 0; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add {
              display: flex;
              align-items: center; }
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add input {
                box-sizing: border-box;
                margin: 0 11px;
                height: 32px;
                padding: 0 4px;
                font-family: "RobotoMedium", sans-serif;
                font-size: 14px;
                text-align: center;
                border: 1.5px solid rgba(48, 49, 51, 0.15);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
                transition: 0.3s;
                width: 50px;
                border-radius: 4px !important; }
                .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add input:focus {
                  border-color: rgba(48, 49, 51, 0.3); }
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-minus,
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-plus {
                cursor: pointer;
                width: 12px; }
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-minus {
                padding: 15px 0; }
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-plus {
                padding: 10px 0; }
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .input_add .edit_btn {
                margin-left: 11px; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .price {
              display: flex;
              align-items: center; }
              .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .price span {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #1a202c; }
                .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .price span.discount {
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(74, 85, 104, 0.5);
                  position: relative;
                  margin-right: 20px; }
                  .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.body .price span.discount::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: -5px;
                    width: calc(100% + 10px);
                    height: 1px;
                    -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                    background-color: #ff010b; }
          .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.footer {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.footer img {
              width: 16px;
              height: 16px;
              margin-right: 6px; }
            .custom_dialog .dialog_cart .with_cart .client_content.client_info .cart:not(.empty) > div.cart-item > .container .info.footer span {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: #4a5568; }
  .custom_dialog .dialog_cart .cart_add {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: #FFFFFF;
    border-radius: 8px;
    background: #1cbb32; }

.custom_dialog .default_button_wrapper {
  width: 100%; }

.custom_dialog .no_items {
  margin-left: 24px;
  padding-bottom: 24px;
  opacity: 0.7;
  font-size: 12px; }

.page-animation-enter {
  opacity: 0.01; }

.page-animation-enter.page-animation-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.page-animation-leave {
  opacity: 1; }

.page-animation-leave.page-animation-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }

.page-animation-appear {
  opacity: 0.01; }

.page-animation-appear.page-animation-appear-active {
  opacity: 1;
  transition: all 1s ease; }

.shop_page {
  display: flex;
  flex-flow: initial; }

.shop_content {
  padding-top: 36px;
  display: flex;
  flex-flow: column;
  width: 100%;
  overflow-x: auto;
  max-height: 100%; }
  .shop_content::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  .shop_content::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #fafafa; }
  .shop_content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(74, 85, 104, 0.25); }
  .shop_content::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(74, 85, 104, 0.25); }
  .shop_content .catalog_product {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }

.mb40 {
  margin-bottom: 40px; }

.more-products {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
  cursor: pointer; }
  .more-products > span {
    color: #ff010b;
    font-family: "RobotoMedium", sans-serif;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase; }

.product {
  position: relative;
  margin-bottom: 14px;
  min-width: 181px;
  width: 181px;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(48, 49, 51, 0.1);
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  margin-right: 12px; }
  .product .dots-wrapper {
    cursor: pointer; }
  .product .no-items {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    min-height: 38px;
    display: flex;
    align-items: center; }
  .product .cart-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px; }
    .product .cart-wrapper .cart-added-wrapper,
    .product .cart-wrapper .cart-add-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      background: #f3f5f6;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.03);
      border-radius: 8px; }
      .product .cart-wrapper .cart-added-wrapper.disabled,
      .product .cart-wrapper .cart-add-wrapper.disabled {
        opacity: 0.3; }
    .product .cart-wrapper .cart-add-wrapper {
      background: #1cbb32;
      cursor: pointer; }
    .product .cart-wrapper .cart-no-items-add {
      background: #ff010b; }
  .product .input_add {
    display: flex;
    align-items: center; }
    .product .input_add input {
      box-sizing: border-box;
      margin: 0 15px;
      height: 32px;
      padding: 0 4px;
      font-family: "RobotoMedium", sans-serif;
      font-size: 14px;
      text-align: center;
      border: 1.5px solid rgba(48, 49, 51, 0.15);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      width: 40px;
      border-radius: 4px !important; }
      .product .input_add input:focus {
        border-color: rgba(48, 49, 51, 0.3); }
    .product .input_add .cart-minus,
    .product .input_add .cart-plus {
      cursor: pointer;
      width: 12px; }
    .product .input_add .cart-minus {
      padding: 15px 0; }
    .product .input_add .cart-plus {
      padding: 10px 0; }
  .product .shops-wrapper {
    background: #ffffff;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.03), 0px 4px 10px rgba(68, 68, 79, 0.08);
    border-radius: 8px;
    padding: 8px;
    margin-top: 6px; }
    .product .shops-wrapper > p {
      color: #4a5568;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      margin-bottom: 10px; }
    .product .shops-wrapper .error-download {
      margin-top: 10px; }
    .product .shops-wrapper-content > div {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .product .shops-wrapper-content > div:last-of-type {
        margin-bottom: 0px; }
      .product .shops-wrapper-content > div p {
        color: #4a5568;
        font-size: 11px;
        line-height: 14px; }
        .product .shops-wrapper-content > div p:last-child {
          white-space: nowrap; }
    .product .shops-wrapper-content .shop-item-name {
      max-width: 73px;
      margin-right: 23px; }
  .product .recent-products {
    min-height: 22px;
    margin-top: 12px;
    display: flex;
    align-items: center; }
    .product .recent-products-text {
      color: #4a5568;
      font-size: 12px;
      line-height: 14px;
      margin-right: 6px; }
    .product .recent-products .whiteplus-wrapper,
    .product .recent-products .minus-wrapper {
      cursor: pointer;
      background: #1cbb32;
      border-radius: 50px;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .product .recent-products .minus-wrapper {
      background: #f3f5f6; }
  .product .art {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    background: rgba(207, 216, 220, 0.25);
    border-radius: 0px 8px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 12px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #1a202c; }
  .product .icon {
    display: flex;
    justify-content: center; }
    .product .icon img {
      margin-bottom: 14px;
      width: 100%;
      height: 100px;
      object-fit: contain;
      max-width: 70px;
      max-height: 70px; }
  .product .name {
    min-height: 40px;
    color: #1a202c;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
    position: relative; }
    .product .name .show {
      position: absolute;
      top: 20px;
      right: 0; }
    .product .name .hide {
      position: absolute;
      bottom: 0px;
      right: 0; }
  .product .price-wrapper {
    display: flex;
    align-items: center; }
  .product .price_product {
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px; }
    .product .price_product.discount {
      position: relative;
      margin-right: 9px;
      color: rgba(74, 85, 104, 0.5);
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      width: -webkit-max-content;
      width: max-content; }
      .product .price_product.discount:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: #ff010b;
        top: calc(50%);
        left: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .product .in_stock {
    padding-top: 10px;
    display: flex;
    align-items: center; }
    .product .in_stock img {
      margin-right: 9px; }
    .product .in_stock span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1cbb32; }
  .product .pre_order {
    padding-top: 10px;
    display: flex;
    align-items: center; }
    .product .pre_order img {
      margin-right: 9px; }
    .product .pre_order span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ff010b; }
  .product .info_product_ {
    position: absolute;
    left: 10px;
    top: 10px; }

.content_header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  margin-right: 18px; }

.title_page {
  margin-right: 16px;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px; }

.catalog_wrapper {
  min-width: 327px;
  margin-right: 28px; }

.catalog_product {
  z-index: 0; }

@media only screen and (max-width: 1279px) {
  .catalog_wrapper {
    min-width: 215px;
    width: 215px;
    margin-right: 15px; }
  .shop_content {
    padding-top: 20px; }
    .shop_content .catalog_product .product {
      min-width: auto; } }

.card-discount {
  position: relative; }
  .card-discount > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px; }

.filter_wrap {
  position: relative;
  display: inline-flex;
  margin-right: auto; }
  .filter_wrap__btn {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    min-height: 27px;
    font-size: 14px;
    line-height: 24px;
    color: #4a5568; }
    .filter_wrap__btn:hover {
      opacity: .7; }
    .filter_wrap__btn.popover_btn {
      padding: 12px 12px 14px;
      width: 100%;
      border-bottom: 1px solid #e1ecf1; }
  .filter_wrap__count {
    display: grid;
    place-items: center;
    min-width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: rgba(255, 1, 11, 0.95);
    border: 1.5px solid #ff010b;
    border-radius: 50%; }
  .filter_wrap__clear_btn {
    padding: 6px 16px;
    font-size: 13px;
    line-height: 18px;
    color: #4a5568;
    transition: opacity .3s; }
    .filter_wrap__clear_btn span {
      border-bottom: 1px dashed #4a5568; }
    .filter_wrap__clear_btn:hover {
      opacity: .7; }
  .filter_wrap__popover {
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 1;
    min-width: 219px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(48, 49, 51, 0.12); }
  .filter_wrap__list {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px 14px; }
  .filter_wrap__item input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .filter_wrap__item input:checked + label:before {
      background-image: url(/static/media/checkbox.66be922a.svg);
      background-color: #1cbb32;
      border-color: #1cbb32; }
  .filter_wrap__item label {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #4a5568;
    cursor: pointer;
    transition: opacity .3s; }
    .filter_wrap__item label:hover {
      opacity: .7; }
    .filter_wrap__item label:before {
      content: '';
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      border: 1px solid #cbd5e0;
      border-radius: 4px; }

.sort_wrap {
  position: relative;
  margin-left: auto; }
  .sort_wrap__btn {
    display: flex;
    align-items: center;
    grid-gap: 9px;
    gap: 9px;
    font-size: 14px;
    line-height: 24px;
    color: #4a5568; }
    .sort_wrap__btn:hover {
      opacity: .7; }
    .sort_wrap__btn.popover_btn {
      padding: 12px 12px 14px;
      width: 100%;
      border-bottom: 1px solid #e1ecf1; }
  .sort_wrap__popover {
    position: absolute;
    top: -4px;
    right: 0;
    z-index: 1;
    min-width: 219px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(48, 49, 51, 0.12); }
  .sort_wrap__list {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px 14px; }
  .sort_wrap__item input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .sort_wrap__item input:checked + label:before {
      background-image: url(/static/media/checkmark.8625c274.svg); }
  .sort_wrap__item label {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #4a5568;
    cursor: pointer;
    transition: opacity .3s; }
    .sort_wrap__item label:hover {
      opacity: .7; }
    .sort_wrap__item label:before {
      content: '';
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center; }

.search_page {
  padding: 32px;
  box-sizing: border-box; }
  .search_page .search_info {
    width: 100%;
    box-sizing: border-box; }
    .search_page .search_info .wrapper_scroll {
      max-height: 575px; }
      .search_page .search_info .wrapper_scroll::-webkit-scrollbar {
        width: 10px;
        height: 10px; }
      .search_page .search_info .wrapper_scroll::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #fafafa; }
      .search_page .search_info .wrapper_scroll::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(74, 85, 104, 0.25); }
      .search_page .search_info .wrapper_scroll::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(74, 85, 104, 0.25); }
      .search_page .search_info .wrapper_scroll .info {
        padding-top: 16px;
        display: flex;
        flex-flow: column; }
        .search_page .search_info .wrapper_scroll .info .cat_img {
          width: 140px;
          height: 90px;
          object-fit: cover; }
        .search_page .search_info .wrapper_scroll .info p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 6px;
          display: flex; }
        .search_page .search_info .wrapper_scroll .info > div {
          display: flex;
          flex-wrap: wrap; }
          .search_page .search_info .wrapper_scroll .info > div span {
            margin-right: 15px;
            display: flex;
            flex-flow: column;
            font-size: 14px;
            line-height: 21px;
            padding-bottom: 14px;
            width: 140px;
            max-width: 140px; }
            .search_page .search_info .wrapper_scroll .info > div span p {
              font-weight: 500;
              font-size: 16px;
              line-height: 25px;
              display: flex;
              justify-content: center; }
              .search_page .search_info .wrapper_scroll .info > div span p img {
                background-size: 100% 100%;
                max-width: 140px; }
      .search_page .search_info .wrapper_scroll .info:last-of-type span:last-of-type {
        padding: 0; }
      .search_page .search_info .wrapper_scroll .title_search {
        padding-bottom: 18px;
        font-weight: 500;
        font-size: 24px;
        display: flex;
        line-height: 28px; }
      .search_page .search_info .wrapper_scroll .product_search {
        display: flex;
        align-items: flex-start;
        flex-flow: column; }
        .search_page .search_info .wrapper_scroll .product_search .product_info {
          padding-bottom: 24px;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info {
            display: flex;
            align-items: center; }
            .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .img {
              margin-right: 24px; }
              .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .img img {
                width: 80px;
                height: 80px;
                background-size: 100% 100%; }
            .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .name {
              font-size: 16px;
              max-width: 420px;
              line-height: 21px;
              display: flex;
              padding-bottom: 6px;
              align-items: center;
              position: relative;
              padding-right: 60px; }
              .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .name p {
                font-weight: 500; }
              .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .name span {
                position: absolute;
                top: calc(50% - 3px);
                right: 15px;
                -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                margin-left: 12px; }
                .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .name span img {
                  background-size: 100% 100%;
                  width: 32px;
                  height: 32px; }
            .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info {
              display: flex;
              align-items: center; }
              .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .price {
                font-weight: bold;
                font-size: 24px;
                line-height: 28px; }
              .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .stock {
                color: #ff010b;
                display: flex;
                align-items: flex-end; }
                .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .stock p {
                  margin-left: 16px;
                  font-size: 16px;
                  line-height: 19px;
                  color: rgba(74, 85, 104, 0.5);
                  padding-bottom: 3px;
                  position: relative; }
                  .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .stock p:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: calc(50% - 0.5px);
                    height: 1px;
                    background: #ff010b;
                    width: 100%; }
              .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .art {
                margin-right: 18px;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                padding: 8px 16px;
                background: rgba(203, 213, 224, 0.25);
                border-radius: 8px; }
          .search_page .search_info .wrapper_scroll .product_search .product_info .info_yes {
            position: absolute;
            right: 45px;
            top: 0;
            background: rgba(28, 187, 50, 0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #1cbb32;
            width: 138px;
            height: 32px; }
            .search_page .search_info .wrapper_scroll .product_search .product_info .info_yes img {
              margin-right: 8px; }
          .search_page .search_info .wrapper_scroll .product_search .product_info .info_no {
            position: absolute;
            right: 45px;
            top: 0;
            background: rgba(255, 1, 11, 0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #FF010B;
            width: 138px;
            height: 32px; }
            .search_page .search_info .wrapper_scroll .product_search .product_info .info_no img {
              margin-right: 8px; }

@media only screen and (max-width: 1279px) {
  .search_page {
    height: 100%; }
    .search_page .search_info {
      height: 100%; }
      .search_page .search_info .wrapper_scroll {
        height: 100%;
        max-height: 100%; }
        .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .img {
          margin-right: 15px; }
          .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .img img {
            width: 60px;
            height: 60px; }
        .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .name {
          font-size: 15px;
          line-height: 18px; }
        .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .art {
          margin-right: 15px;
          font-size: 13px;
          line-height: 13px;
          padding: 6px 14px; }
        .search_page .search_info .wrapper_scroll .product_search .product_info .wrapper_info .prod_info .wrapper_info .price {
          font-size: 20px;
          line-height: 23px; }
        .search_page .search_info .wrapper_scroll .product_search .product_info .info_yes {
          font-size: 13px;
          line-height: 14px;
          width: 125px;
          height: 30px; }
        .search_page .search_info .wrapper_scroll .product_search .product_info .info_no {
          font-size: 13px;
          line-height: 14px;
          width: 125px;
          height: 30px; }
        .search_page .search_info .wrapper_scroll .info > p {
          padding-bottom: 10px; }
        .search_page .search_info .wrapper_scroll .info > div span p {
          font-size: 14px;
          padding-top: 4px;
          line-height: 18px; }
        .search_page .search_info .wrapper_scroll .info .cat_img {
          background-size: 100% 100%;
          width: 120px;
          height: 75px; } }

.custom_input_wrapper {
  width: 100%;
  height: 35px;
  margin-bottom: 36px !important; }
  .custom_input_wrapper:hover .custom_input_outline {
    border-color: #1C2225; }
  .custom_input_wrapper .custom_input_label {
    font-family: "Roboto", sans-serif;
    color: #4A5568;
    font-size: 16px;
    -webkit-transform: translate(0px, 10px) scale(1);
            transform: translate(0px, 10px) scale(1);
    transition: all 0.1s linear; }
    .custom_input_wrapper .custom_input_label.custom_input_label_focused {
      color: #4A5568;
      opacity: 1;
      font-family: "Roboto", sans-serif; }
    .custom_input_wrapper .custom_input_label.custom_input_label_active {
      font-family: "Roboto", sans-serif;
      color: #4A5568;
      opacity: 1;
      font-size: 14px;
      -webkit-transform: translate(0px, -14px);
              transform: translate(0px, -14px); }
    .custom_input_wrapper .custom_input_label.MuiFormLabel-filled + .phone_number {
      padding-left: 45px; }
      .custom_input_wrapper .custom_input_label.MuiFormLabel-filled + .phone_number:before {
        opacity: .7; }
  .custom_input_wrapper .custom_input {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: #FFFFFF;
    color: #373F51;
    font-size: 16px;
    border-radius: 0px; }
    .custom_input_wrapper .custom_input:focus {
      background-color: #FFFFFF; }
    .custom_input_wrapper .custom_input input {
      height: 35px;
      padding: 0px;
      border-radius: 0;
      color: #1E282C;
      font-family: "Roboto",sans-serif;
      font-weight: 500;
      font-size: 16px; }
    .custom_input_wrapper .custom_input .custom_input_outline {
      border: none;
      border-bottom: 1px solid #CFD8DC; }
      .custom_input_wrapper .custom_input .custom_input_outline legend {
        width: 0 !important; }
    .custom_input_wrapper .custom_input.custom_input_active {
      border-color: red !important; }
    .custom_input_wrapper .custom_input.custom_input_focused {
      background-color: #FFFFFF; }
      .custom_input_wrapper .custom_input.custom_input_focused .custom_input_outline {
        border: none;
        border-bottom: 1px solid #1C2225; }
    .custom_input_wrapper .custom_input.custom_input_error .custom_input_outline, .custom_input_wrapper .custom_input.custom_input_error:hover .custom_input_outline {
      border-color: #FF1919 !important; }
    .custom_input_wrapper .custom_input.phone_number {
      position: relative; }
      .custom_input_wrapper .custom_input.phone_number:before {
        content: '+380';
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        opacity: 0;
        transition: opacity 250ms; }
      .custom_input_wrapper .custom_input.phone_number.custom_input_focused {
        padding-left: 45px; }
        .custom_input_wrapper .custom_input.phone_number.custom_input_focused:before {
          opacity: .7; }

.error_text {
  color: #FF1919;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: "Roboto",sans-serif;
  font-size: 10px;
  line-height: 13px;
  display: block; }

@media only screen and (max-width: 1279px) {
  .custom_input_wrapper {
    z-index: 0;
    height: 30px;
    margin-bottom: 30px !important; }
    .custom_input_wrapper .custom_input input {
      height: 30px; }
    .custom_input_wrapper .custom_input_label {
      font-size: 15px; }
      .custom_input_wrapper .custom_input_label.custom_input_label_active {
        -webkit-transform: translate(0px, -10px);
                transform: translate(0px, -10px);
        font-size: 13px; } }

.product_request_page {
  background: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .product_request_page .request_content {
    width: 460px;
    max-width: 460px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    padding: 36px 40px;
    box-sizing: border-box; }
    .product_request_page .request_content .title {
      padding-bottom: 36px;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      text-align: center; }
    .product_request_page .request_content form > button {
      width: 100%;
      height: 52px;
      background: #1CBB32;
      box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      cursor: pointer; }

.dialog_request {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  align-items: center; }
  .dialog_request > div {
    font-weight: 500;
    font-size: 28px;
    text-align: center; }
  .dialog_request > img {
    margin-bottom: 32px; }

@media only screen and (max-width: 1279px) {
  .product_request_page {
    padding: 20px 0;
    box-sizing: border-box;
    overflow-y: auto;
    align-items: flex-start; }
    .product_request_page .request_content {
      padding: 35px;
      height: auto; }
      .product_request_page .request_content .title {
        padding-bottom: 30px;
        font-size: 25px;
        line-height: 30px; }
  .dialog_request {
    padding: 40px 0; }
    .dialog_request > div {
      font-size: 25px; }
    .dialog_request > img {
      margin-bottom: 25px; } }

.toastify_custom {
  font-size: 15px !important; }
  .toastify_custom div {
    padding: 0; }
  .toastify_custom .Toastify__toast-container {
    top: 85px !important; }
  .toastify_custom .Toastify__toast {
    padding: 10px; }
  .toastify_custom .Toastify__close-button {
    display: none; }

.all_info {
  background: #fafafa;
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: 0 32px 0; }
  .all_info__btn-group {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 16px; }
  .all_info__outline-btn {
    padding: 6px 8px;
    max-width: 126px;
    font-size: 13px;
    line-height: 18px;
    color: #4a5568;
    border: 1px solid rgba(74, 85, 104, 0.9);
    border-radius: 8px;
    transition: color 0.3s, border-color 0.3s; }
    .all_info__outline-btn:hover, .all_info__outline-btn:focus-visible {
      color: #1cbb32;
      border-color: #1cbb32; }
  .all_info .all_info_block {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 32px 0 16px;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between; }
    .all_info .all_info_block::-webkit-scrollbar {
      width: 10px;
      height: 10px; }
    .all_info .all_info_block::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #fafafa; }
    .all_info .all_info_block::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(74, 85, 104, 0.25); }
    .all_info .all_info_block::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(74, 85, 104, 0.25); }
  .all_info .specifications {
    display: flex;
    flex-flow: column; }
    .all_info .specifications .title_block {
      height: 26px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-transform: capitalize;
      color: #1a202c;
      padding-bottom: 18px; }
    .all_info .specifications .info_wrapper {
      display: flex;
      flex-flow: column; }
      .all_info .specifications .info_wrapper .add_info {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ff010b; }
        .all_info .specifications .info_wrapper .add_info img {
          margin-left: 12px; }
    .all_info .specifications .block {
      display: flex;
      align-items: flex-start;
      padding-bottom: 14px; }
      .all_info .specifications .block span {
        width: 170px;
        min-width: 170px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1a202c;
        padding-right: 30px; }
      .all_info .specifications .block p {
        font-size: 16px;
        line-height: 24px;
        color: #4a5568; }
  .all_info .icon_product_wrapper {
    margin-right: 48px;
    display: flex; }
    .all_info .icon_product_wrapper .big_icon {
      background-size: 100% 100%;
      width: 322px;
      height: 322px; }
      .all_info .icon_product_wrapper .big_icon img {
        height: 100%;
        width: 100%;
        object-fit: contain; }
    .all_info .icon_product_wrapper .mini_icon {
      display: flex;
      flex-flow: column;
      margin-right: 22px;
      overflow-y: auto; }
      .all_info .icon_product_wrapper .mini_icon > div {
        margin-bottom: 14px;
        border: 1.5px solid transparent;
        box-sizing: border-box;
        border-radius: 8px;
        background: #ffffff;
        width: 70px;
        height: 70px; }
        .all_info .icon_product_wrapper .mini_icon > div img {
          width: 66px;
          height: 66px;
          border-radius: 8px;
          object-fit: cover; }
      .all_info .icon_product_wrapper .mini_icon > div:last-of-type {
        margin-bottom: 0; }
      .all_info .icon_product_wrapper .mini_icon .active {
        border: 1.5px solid #cbd5e0; }
  .all_info .info_wrapper {
    min-width: 300px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-right: 60px; }
    .all_info .info_wrapper .info_yes {
      min-height: 41px;
      background: rgba(28, 187, 50, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1cbb32;
      width: auto;
      padding: 0 20px;
      height: 41px; }
      .all_info .info_wrapper .info_yes img {
        margin-right: 8px; }
    .all_info .info_wrapper .info_no {
      min-height: 41px;
      background: rgba(255, 1, 11, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ff010b;
      width: auto;
      padding: 0 20px;
      height: 41px; }
      .all_info .info_wrapper .info_no img {
        margin-right: 8px; }
    .all_info .info_wrapper .price {
      padding: 16px 0 12px;
      display: flex;
      flex-flow: column; }
      .all_info .info_wrapper .price span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #4a5568;
        display: flex;
        align-items: center;
        padding-bottom: 4px; }
        .all_info .info_wrapper .price span > div {
          margin-left: 11px; }
          .all_info .info_wrapper .price span > div div {
            text-transform: initial;
            font-size: 12px;
            padding: 5px 8px;
            line-height: 14px;
            height: auto;
            min-height: auto; }
      .all_info .info_wrapper .price p {
        color: #1a202c;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px; }
    .all_info .info_wrapper .discount_price p {
      color: #ff010b; }
    .all_info .info_wrapper .price_client {
      display: flex; }
      .all_info .info_wrapper .price_client > div:first-of-type {
        margin-right: 28px; }
      .all_info .info_wrapper .price_client > div {
        display: flex;
        flex-flow: column; }
        .all_info .info_wrapper .price_client > div span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #4a5568;
          display: flex;
          padding-bottom: 4px; }
        .all_info .info_wrapper .price_client > div p {
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
          color: #1a202c; }
      .all_info .info_wrapper .price_client + .availability {
        margin-top: 20px; }
    .all_info .info_wrapper .availability {
      padding-top: 28px;
      display: flex;
      flex-flow: column; }
      .all_info .info_wrapper .availability .descriptions {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #1e282c;
        padding-bottom: 16px; }
      .all_info .info_wrapper .availability .block {
        display: flex;
        padding-bottom: 12px; }
        .all_info .info_wrapper .availability .block span {
          min-width: 150px;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          color: #1e282c; }
        .all_info .info_wrapper .availability .block p {
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          color: #2d3d45;
          padding-left: 12px; }
      .all_info .info_wrapper .availability_table tr th,
      .all_info .info_wrapper .availability_table tr td {
        font-size: 12px;
        line-height: 14px;
        padding: 8px; }
        .all_info .info_wrapper .availability_table tr th:not(:first-child),
        .all_info .info_wrapper .availability_table tr td:not(:first-child) {
          text-align: center; }
        .all_info .info_wrapper .availability_table tr th:first-child,
        .all_info .info_wrapper .availability_table tr td:first-child {
          padding-left: 0; }
          .all_info .info_wrapper .availability_table tr th:first-child span,
          .all_info .info_wrapper .availability_table tr td:first-child span {
            font-weight: 500;
            margin-left: 4px; }
        .all_info .info_wrapper .availability_table tr th:nth-child(2),
        .all_info .info_wrapper .availability_table tr td:nth-child(2) {
          background-color: #e2e9ff; }
        .all_info .info_wrapper .availability_table tr th:nth-child(3),
        .all_info .info_wrapper .availability_table tr td:nth-child(3) {
          background-color: #f1f7ff; }
      .all_info .info_wrapper .availability_table tr:first-child th,
      .all_info .info_wrapper .availability_table tr:first-child td {
        font-weight: 500; }
      .all_info .info_wrapper .availability_table tr:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08); }

.add_block {
  display: flex;
  margin-bottom: 20px; }
  .add_block .input_add {
    display: flex;
    align-items: center; }
    .add_block .input_add input {
      box-sizing: border-box;
      margin: 0 11px;
      height: 32px;
      padding: 0 4px;
      font-family: "RobotoMedium", sans-serif;
      font-size: 14px;
      text-align: center;
      border: 1.5px solid rgba(48, 49, 51, 0.15);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      width: 50px;
      border-radius: 4px !important; }
      .add_block .input_add input:focus {
        border-color: rgba(48, 49, 51, 0.3); }
    .add_block .input_add .cart-minus,
    .add_block .input_add .cart-plus {
      cursor: pointer;
      width: 12px; }
    .add_block .input_add .cart-minus {
      padding: 15px 0; }
    .add_block .input_add .cart-plus {
      padding: 10px 0; }
  .add_block .cart-add-wrapper,
  .add_block .cart-added-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    padding: 0 15px;
    height: 38px;
    background: #f3f5f6;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff; }
    .add_block .cart-add-wrapper img:not(.added_img),
    .add_block .cart-added-wrapper img:not(.added_img) {
      margin-right: 12px; }
    .add_block .cart-add-wrapper.cart-add-wrapper,
    .add_block .cart-added-wrapper.cart-add-wrapper {
      background: #1cbb32;
      cursor: pointer; }
    .add_block .cart-add-wrapper.cart-no-items-add,
    .add_block .cart-added-wrapper.cart-no-items-add {
      background: #ff010b; }
    .add_block .cart-add-wrapper.disabled,
    .add_block .cart-added-wrapper.disabled {
      opacity: 0.3; }

.dialog_product {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  align-items: center; }
  .dialog_product > div {
    font-weight: 500;
    font-size: 28px;
    text-align: center; }
  .dialog_product > img {
    margin-bottom: 32px; }
  .dialog_product.lightbox {
    padding: 70px 0 0; }
    .dialog_product.lightbox .pinch-zoom-container {
      min-height: 300px;
      width: 460px; }
      .dialog_product.lightbox .pinch-zoom-container img {
        -webkit-transform-origin: -35% 0% !important;
                transform-origin: -35% 0% !important; }

.dialog_supplier {
  padding: 23px 28px 20px;
  display: flex;
  flex-flow: column;
  width: 100%; }
  .dialog_supplier .storage_error {
    color: red;
    margin-bottom: 12px;
    font-size: 12px; }
  .dialog_supplier .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 24px;
    display: flex; }
  .dialog_supplier .descriptions {
    font-size: 14px;
    line-height: 16px;
    color: #4a5568;
    margin-bottom: 12px;
    display: flex; }
  .dialog_supplier textarea {
    resize: none;
    background: #ffffff;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    padding: 16px;
    margin-bottom: 24px;
    overflow: auto; }
    .dialog_supplier textarea::-webkit-scrollbar {
      width: 0px;
      height: 0px; }
  .dialog_supplier .btn_dialog {
    display: flex;
    justify-content: space-between; }
    .dialog_supplier .btn_dialog .default_button_wrapper {
      width: calc(50% - 8px); }
      .dialog_supplier .btn_dialog .default_button_wrapper button {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        height: 40px; }

@media only screen and (max-width: 1279px) {
  .dialog_product {
    padding: 40px 0; }
    .dialog_product > div {
      font-size: 25px; }
    .dialog_product > img {
      margin-bottom: 25px; }
  .all_info {
    height: auto; }
    .all_info .all_info_block {
      flex-wrap: wrap;
      height: auto; }
      .all_info .all_info_block .icon_product_wrapper {
        width: calc(60% - 20px);
        margin-right: 20px; }
        .all_info .all_info_block .icon_product_wrapper .mini_icon {
          margin-right: 15px;
          max-height: 280px; }
          .all_info .all_info_block .icon_product_wrapper .mini_icon > div {
            width: 60px;
            height: 60px; }
            .all_info .all_info_block .icon_product_wrapper .mini_icon > div img {
              width: 56px;
              height: 56px; }
        .all_info .all_info_block .icon_product_wrapper .big_icon {
          width: 280px;
          height: 280px; }
      .all_info .all_info_block .info_wrapper {
        min-width: auto;
        width: 40%;
        margin-right: 0; }
        .all_info .all_info_block .info_wrapper .availability {
          padding-top: 0; }
          .all_info .all_info_block .info_wrapper .availability .btn_green {
            width: 100%; }
      .all_info .all_info_block .specifications {
        width: 100%;
        padding-top: 25px; }
        .all_info .all_info_block .specifications .info_wrapper {
          width: 100%; }
          .all_info .all_info_block .specifications .info_wrapper .block span {
            font-size: 15px;
            line-height: 20px;
            padding-right: 25px;
            width: 240px;
            min-width: 240px; }
          .all_info .all_info_block .specifications .info_wrapper .block p {
            font-size: 15px;
            line-height: 20px; } }

.special_discount {
  border-top: 1px dashed rgba(74, 85, 104, 0.5);
  border-bottom: 1px dashed rgba(74, 85, 104, 0.5);
  margin-bottom: 15px;
  padding: 10px 0 0;
  overflow-y: hidden; }
  .special_discount .MuiFormControlLabel-root {
    margin: 0; }
    .special_discount .MuiFormControlLabel-root > span:first-child {
      padding: 0;
      margin-right: 10px; }
  .special_discount .inner {
    height: 0;
    margin-top: 10px;
    transition: height 150ms, margin 150ms;
    position: relative; }
    .special_discount .inner .error {
      display: none; }
    .special_discount .inner.open {
      height: 55px;
      margin-bottom: 20px; }
      .special_discount .inner.open .error {
        display: inline-block;
        position: absolute;
        top: 100%;
        color: red;
        left: 0%;
        width: 100%;
        font-size: 12px; }
    .special_discount .inner .line {
      display: flex;
      align-items: center; }
      .special_discount .inner .line input {
        box-sizing: border-box;
        margin: 0 11px;
        height: 32px;
        padding: 0 4px;
        font-family: "RobotoMedium", sans-serif;
        font-size: 14px;
        text-align: center;
        border: 1.5px solid rgba(48, 49, 51, 0.15);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        width: 32px;
        border-radius: 4px !important; }

.select_wrapper {
  width: 100%; }

.select_component {
  height: 35px;
  width: 100%; }
  .select_component.select--is-disabled {
    cursor: not-allowed !important; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component .select__control {
    min-height: 35px;
    box-sizing: border-box;
    border-radius: 0px;
    padding: 0;
    height: 100%;
    background-color: #ffffff;
    color: #1E282C !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
    border: 1px solid transparent;
    border-bottom: 1px solid #cfd8dc; }
    .select_component .select__control:hover {
      cursor: pointer;
      border: 1px solid transparent !important;
      border-bottom: 1px solid #1C2225 !important; }
    .select_component .select__control .select__placeholder {
      color: #4A5568;
      font-family: "Roboto",sans-serif !important;
      font-size: 16px;
      line-height: 19px;
      padding-left: 0px;
      margin: 0 !important; }
    .select_component .select__control .select__value-container {
      padding: 0 !important; }
    .select_component .select__control .select__single-value {
      color: #383838;
      margin: 0 !important;
      font-size: 16px;
      font-family: "Roboto",sans-serif !important;
      text-transform: capitalize;
      padding-left: 0px !important; }
    .select_component .select__control .select__indicators > div {
      padding: 0 !important; }
    .select_component .select__control .select__indicator-separator {
      background: none; }
    .select_component .select__control .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center; }
      .select_component .select__control .select_indicator img {
        transition: all 0.3s linear; }
  .select_component .select__control--is-focused {
    box-shadow: none; }
    .select_component .select__control--is-focused .indicator_active img {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__menu {
    z-index: 9999;
    border-radius: 4px !important;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    border: none !important;
    margin-top: 8px !important; }
    .select_component .select__menu .select__menu-list {
      font-size: 14px;
      max-height: 116px;
      font-family: "Roboto",sans-serif !important;
      color: #020018;
      background: transparent; }
      .select_component .select__menu .select__menu-list .select__option {
        padding: 10px;
        background: transparent;
        color: #020018;
        cursor: pointer;
        transition: all 0.3s linear; }
      .select_component .select__menu .select__menu-list .select__option--is-selected {
        color: #020018;
        font-family: "Roboto",sans-serif !important;
        background-color: #ffffff;
        text-transform: capitalize; }
      .select_component .select__menu .select__menu-list .status_select {
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent; }
        .select_component .select__menu .select__menu-list .status_select:hover {
          background: transparent; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar {
        width: 5px;
        height: 5px; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #f2f2f3; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(74, 85, 104, 0.25); }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(74, 85, 104, 0.25); }
  .select_component input {
    height: unset; }

@media only screen and (max-width: 1279px) {
  .select_compone1nt {
    height: 30px; }
    .select_compone1nt .select__control {
      min-height: 30px; }
  .select_component .select__control .select__placeholder {
    font-size: 15px; }
  .select_component .select__control .select__single-value {
    font-size: 15px; } }

.adjustment_dialog .paper_custom_dialog {
  width: 424px;
  min-width: 424px; }

.adjustment_dialog .dialog_wrapper {
  display: block;
  padding: 23px 28px 24px; }

.adjustment_dialog__title {
  margin-bottom: 24px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500; }

.adjustment_dialog__columns {
  display: flex;
  grid-gap: 32px;
  gap: 32px; }

.adjustment_dialog__label {
  display: inline-block;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 16px;
  color: #4a5568; }

.adjustment_dialog__select {
  min-width: 186px; }
  .adjustment_dialog__select .select_component {
    min-height: 44px; }
  .adjustment_dialog__select .select__control {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    border: 1px solid #cbd5e0; }
    .adjustment_dialog__select .select__control:hover, .adjustment_dialog__select .select__control:focus-visible {
      border-color: #1c2225 !important; }
    .adjustment_dialog__select .select__control .select__single-value {
      font-size: 14px;
      color: #1A202C; }

.adjustment_dialog__error {
  margin-top: 16px;
  margin-bottom: -16px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #ff010b; }

.adjustment_dialog__btn-group {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 32px; }

.adjustment_dialog__btn {
  flex: 1 1; }
  .adjustment_dialog__btn .MuiButton-root {
    max-height: 40px; }
  .adjustment_dialog__btn .MuiButton-label {
    font-size: 14px; }

.adjustment_dialog .quantity_input {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  min-height: 44px; }
  .adjustment_dialog .quantity_input__btn {
    width: 24px;
    height: 24px; }
    .adjustment_dialog .quantity_input__btn svg path {
      transition: .3s; }
    .adjustment_dialog .quantity_input__btn:hover svg path, .adjustment_dialog .quantity_input__btn:focus-visible svg path {
      fill: #1a202c; }
  .adjustment_dialog .quantity_input__input {
    box-sizing: border-box;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    border: 1.5px solid rgba(48, 49, 51, 0.15);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    transition: border-color .1s; }
    .adjustment_dialog .quantity_input__input:hover, .adjustment_dialog .quantity_input__input:focus-visible {
      border-color: #1c2225; }

.rating_wrapper {
  display: flex;
  align-items: center; }
  .rating_wrapper > span {
    margin-left: 8px;
    color: rgba(245, 166, 35, 0.2);
    font-size: 14px;
    line-height: 17px; }
    .rating_wrapper > span[class^="react-stars"]:before {
      z-index: 0; }
  .rating_wrapper span[class^="react-stars"]:before {
    z-index: 0; }

.buy_together {
  background: #FAFAFA;
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: 0 32px 16px; }
  .buy_together .block_product {
    padding: 32px 0;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; }
    .buy_together .block_product::-webkit-scrollbar {
      width: 10px;
      height: 10px; }
    .buy_together .block_product::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #fafafa; }
    .buy_together .block_product::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(74, 85, 104, 0.25); }
    .buy_together .block_product::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(74, 85, 104, 0.25); }

.specifications_wrapper {
  background: #fafafa;
  display: flex;
  align-items: flex-start;
  padding: 32px 32px 16px; }
  .specifications_wrapper .specifications_block {
    width: 100%;
    overflow: auto;
    height: 335px;
    display: flex; }
    .specifications_wrapper .specifications_block::-webkit-scrollbar {
      width: 10px;
      height: 10px; }
    .specifications_wrapper .specifications_block::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #fafafa; }
    .specifications_wrapper .specifications_block::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(74, 85, 104, 0.25); }
    .specifications_wrapper .specifications_block::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(74, 85, 104, 0.25); }
  .specifications_wrapper .specifications {
    width: 60%;
    min-width: 60%;
    display: flex;
    flex-flow: column; }
    .specifications_wrapper .specifications .info_wrapper {
      display: flex;
      flex-wrap: wrap; }
      .specifications_wrapper .specifications .info_wrapper > div {
        width: 50%; }
    .specifications_wrapper .specifications .block {
      display: flex;
      align-items: flex-start;
      padding-bottom: 14px; }
      .specifications_wrapper .specifications .block span {
        width: 170px;
        min-width: 170px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1a202c;
        padding-right: 30px; }
      .specifications_wrapper .specifications .block p {
        font-size: 16px;
        line-height: 24px;
        color: #4a5568;
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;
        padding-right: 5px; }
  .specifications_wrapper .title_block {
    height: 26px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #1a202c;
    padding-bottom: 18px; }
  .specifications_wrapper .info_product {
    padding-right: 10px;
    display: flex;
    flex-flow: column; }
    .specifications_wrapper .info_product .info {
      font-size: 14px;
      line-height: 20px;
      color: #4a5568; }

@media only screen and (max-width: 1279px) {
  .specifications_wrapper .specifications_block {
    flex-flow: column;
    height: auto; }
    .specifications_wrapper .specifications_block .specifications {
      width: 100%;
      min-width: 100%; }
    .specifications_wrapper .specifications_block .info_product {
      padding-top: 20px;
      padding-right: 0; }
      .specifications_wrapper .specifications_block .info_product .info table tbody tr td {
        padding-right: 20px;
        max-width: 400px; } }

.tab_block {
  padding: 0 32px;
  background: #ffffff;
  border-top: 1px solid rgba(207, 216, 220, 0.5);
  border-bottom: 1px solid rgba(207, 216, 220, 0.5);
  display: flex;
  align-items: center;
  box-sizing: border-box; }
  .tab_block button {
    padding: 16px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2D3D45; }
  .tab_block .active {
    background: #FF010B;
    color: #FFFFFF; }

.wrapper_page_inner {
  display: flex;
  flex-flow: column;
  height: 100%; }
  .wrapper_page_inner > div:nth-child(3) {
    height: 100%; }
  .wrapper_page_inner .title_block_inner {
    padding: 32px 32px 24px; }
    .wrapper_page_inner .title_block_inner .breadcrumbs_product {
      padding-bottom: 32px;
      display: flex;
      align-items: center;
      overflow-x: auto; }
      .wrapper_page_inner .title_block_inner .breadcrumbs_product a {
        color: #4A5568;
        opacity: 0.75;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap; }
        .wrapper_page_inner .title_block_inner .breadcrumbs_product a img {
          margin: 0 14px; }
      .wrapper_page_inner .title_block_inner .breadcrumbs_product span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #4A5568;
        white-space: nowrap; }
    .wrapper_page_inner .title_block_inner .name {
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      display: flex;
      align-items: center; }
      .wrapper_page_inner .title_block_inner .name img {
        margin-right: 12px;
        background-size: 100% 100%;
        width: 36px;
        height: 36px; }
    .wrapper_page_inner .title_block_inner .info_product_inner {
      display: flex;
      justify-content: space-between;
      padding-top: 8px; }
      .wrapper_page_inner .title_block_inner .info_product_inner .art {
        padding: 8px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #1A202C;
        background: rgba(203, 213, 224, 0.25);
        border-radius: 8px; }

@media only screen and (max-width: 1279px) {
  .wrapper_page_inner .title_block_inner {
    padding: 24px 32px; }
    .wrapper_page_inner .title_block_inner .breadcrumbs_product {
      padding-bottom: 20px;
      flex-wrap: wrap; }
      .wrapper_page_inner .title_block_inner .breadcrumbs_product a {
        font-size: 14px;
        line-height: 17px; }
        .wrapper_page_inner .title_block_inner .breadcrumbs_product a img {
          margin: 0px 10px 2px; }
    .wrapper_page_inner .title_block_inner .name {
      font-size: 25px;
      line-height: 27px; }
  .wrapper_page_inner .tab_block button {
    padding: 13px 21px;
    font-size: 15px;
    line-height: 17px; } }

.client_page {
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-flow: column; }
  .client_page.registration {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: column;
    overflow-y: auto; }
    .client_page.registration .select_component .select__control .select__single-value {
      text-transform: none; }
    .client_page.registration > div {
      margin: 50px auto 0; }
      .client_page.registration > div:first-child > a {
        justify-content: flex-start; }
  .client_page .back_block {
    margin-bottom: 33px;
    width: 460px; }
  .client_page .client_content {
    width: 460px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    padding: 36px 40px;
    box-sizing: border-box; }
    .client_page .client_content.waiting {
      position: relative; }
      .client_page .client_content.waiting > *:not(.loader) {
        opacity: 0.3; }
      .client_page .client_content.waiting .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center; }
        .client_page .client_content.waiting .loader .MuiCircularProgress-root {
          color: #ff4048; }
  .client_page .title {
    padding-bottom: 36px;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: center; }
  .client_page .descriptions {
    padding-bottom: 36px;
    margin-top: -20px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #4a5568; }
  .client_page form .btn_green {
    width: 100%;
    height: 52px;
    background: #1cbb32;
    box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    cursor: pointer; }
  .client_page form .back_btn {
    margin-top: 18px; }
  .client_page .client_info.client_content {
    padding: 0; }
    .client_page .client_info.client_content > div {
      padding: 0 20px; }
      .client_page .client_info.client_content > div:not(:last-child) {
        border-bottom: 1px solid #e1ecf1;
        margin-bottom: 14px; }
      .client_page .client_info.client_content > div:first-child {
        padding-top: 20px; }
      .client_page .client_info.client_content > div:last-child {
        padding-bottom: 20px; }
    .client_page .client_info.client_content .title {
      padding-bottom: 30px;
      font-size: 25px;
      line-height: 30px; }
  .client_page .client_info .name {
    padding-bottom: 6px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #1e282c; }
  .client_page .client_info .phone {
    font-size: 14px;
    line-height: 20px;
    color: #4a5568;
    padding-bottom: 16px; }
  .client_page .client_info .mail {
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 16px;
    color: #4a5568; }
  .client_page .client_info .mail_info {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2d3d45;
    padding-bottom: 12px; }
  .client_page .client_info .discount_info_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px !important; }
    .client_page .client_info .discount_info_wrapper img {
      margin-right: 12px; }
    .client_page .client_info .discount_info_wrapper > div {
      display: flex;
      align-items: baseline; }
      .client_page .client_info .discount_info_wrapper > div .discount {
        font-size: 14px;
        line-height: 16px;
        color: #4a5568;
        padding-bottom: 0; }
      .client_page .client_info .discount_info_wrapper > div .discount_info {
        font-weight: bold;
        font-size: 18px;
        color: #1cbb32;
        margin-left: 5px; }
  .client_page .client_info .delivery_info.wrapper, .client_page .client_info .delivery_info.form {
    padding-bottom: 14px; }
  .client_page .client_info .delivery_info.wrapper {
    margin-bottom: 0 !important;
    height: auto; }
    .client_page .client_info .delivery_info.wrapper.need-delivery {
      border-bottom: 1px solid #fff; }
    .client_page .client_info .delivery_info.wrapper > label {
      margin: 0; }
      .client_page .client_info .delivery_info.wrapper > label > span {
        padding: 0 10px 0 0; }
        .client_page .client_info .delivery_info.wrapper > label > span + span {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #4a5568; }
  .client_page .client_info .delivery_info.form {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background-color: rgba(203, 213, 224, 0.25);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
    border: none !important;
    height: 0;
    overflow: hidden;
    transition: height 250ms; }
    .client_page .client_info .delivery_info.form > div:first-child {
      margin-top: 8px; }
    .client_page .client_info .delivery_info.form > div > label {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #4a5568; }
      .client_page .client_info .delivery_info.form > div > label.Mui-focused {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #4a5568; }
      .client_page .client_info .delivery_info.form > div > label.MuiInputLabel-shrink {
        -webkit-transform: translate(0, 6px) scale(0.75);
                transform: translate(0, 6px) scale(0.75);
        font-size: 12px; }
    .client_page .client_info .delivery_info.form.need-delivery {
      border-bottom: 1px solid rgba(203, 213, 224, 0.25);
      height: 125px; }
  .client_page .client_info .sum_info {
    padding-bottom: 14px !important; }
    .client_page .client_info .sum_info:not(.general) {
      border-bottom-style: dashed !important;
      border-bottom-width: 2px !important; }
    .client_page .client_info .sum_info p {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #4a5568; }
      .client_page .client_info .sum_info p span {
        font-weight: 500;
        color: #1e282c; }
    .client_page .client_info .sum_info.general p > span {
      font-size: 14px;
      line-height: 24px; }
  .client_page .client_info .storage_error {
    color: red;
    display: inline-block;
    margin-bottom: 12px;
    font-size: 12px; }
  .client_page .client_info button.end_session {
    width: 100%;
    height: 40px;
    background: #ff010b;
    box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    position: relative; }
    .client_page .client_info button.end_session > .MuiCircularProgress-colorSecondary {
      color: #ffffff;
      position: absolute;
      left: 5px;
      top: 12px;
      height: 15px !important;
      width: 15px !important; }
  .client_page.with_cart {
    flex-direction: row;
    align-items: flex-start;
    overflow-y: auto;
    padding: 20px 0; }
    .client_page.with_cart .client_content.client_info:not(.cart) {
      width: 35%;
      margin: 0 20px;
      height: 100%;
      overflow-y: auto;
      position: sticky;
      top: 0; }
      .client_page.with_cart .client_content.client_info:not(.cart) + div {
        width: 65%;
        margin: 0 20px 0 0; }
    .client_page.with_cart .client_content.client_info.cart {
      width: 100%; }
      .client_page.with_cart .client_content.client_info.cart > div {
        border-bottom: none; }
        .client_page.with_cart .client_content.client_info.cart > div .name {
          padding: 0; }
        .client_page.with_cart .client_content.client_info.cart > div .clear_cart {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #fe1a23; }
        .client_page.with_cart .client_content.client_info.cart > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center; }
      .client_page.with_cart .client_content.client_info.cart:not(:last-child) {
        margin-bottom: 20px; }
      .client_page.with_cart .client_content.client_info.cart.empty p {
        margin-left: 24px;
        padding-bottom: 24px;
        opacity: 0.7;
        font-size: 12px; }
      .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 0; }
        .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: flex-start;
          padding: 15px 0; }
          .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container:not(:last-child) {
            border-bottom: 1px solid #e1ecf1; }
          .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container > .image-wrapper {
            width: 64px;
            padding-right: 16px; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container > .image-wrapper img {
              width: 64px;
              height: 64px;
              object-fit: contain; }
          .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 80px); }
          .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.header {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.header > .heading {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #1a202c; }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.header > .heading .price-indicator {
                font-weight: bold;
                color: #1a202c;
                margin-left: 4px; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.header > .delete {
              margin: -4px 0 0 15px; }
          .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 12px 0 0; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add {
              display: flex;
              align-items: center; }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add input {
                box-sizing: border-box;
                margin: 0 11px;
                height: 32px;
                padding: 0 4px;
                font-family: "RobotoMedium", sans-serif;
                font-size: 14px;
                text-align: center;
                border: 1.5px solid rgba(48, 49, 51, 0.15);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
                transition: 0.3s;
                width: 50px;
                border-radius: 4px !important; }
                .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add input:focus {
                  border-color: rgba(48, 49, 51, 0.3); }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-minus,
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-plus {
                cursor: pointer;
                width: 12px; }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-minus {
                padding: 15px 0; }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add .cart-plus {
                padding: 10px 0; }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .input_add .edit_btn {
                margin-left: 11px; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .price {
              display: flex;
              align-items: center; }
              .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .price span {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #1a202c; }
                .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .price span.discount {
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(74, 85, 104, 0.5);
                  position: relative;
                  margin-right: 20px; }
                  .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.body .price span.discount::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: -5px;
                    width: calc(100% + 10px);
                    height: 1px;
                    -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                    background-color: #ff010b; }
          .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.footer {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.footer img {
              width: 16px;
              height: 16px;
              margin-right: 6px; }
            .client_page.with_cart .client_content.client_info.cart:not(.empty) > div.cart-item > .container .info.footer span {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: #4a5568; }
  .client_page .no_items {
    margin-left: 24px;
    padding-bottom: 24px;
    opacity: 0.7;
    font-size: 12px; }

.dialog_client {
  justify-content: center;
  padding: 60px 40px 3px;
  width: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box; }
  .dialog_client > img {
    margin: 0 auto 32px;
    max-width: 200px; }
  .dialog_client > div {
    font-weight: 500;
    font-size: 28px;
    color: #1a202c;
    text-align: center;
    margin-bottom: 29px; }
  .dialog_client.quantity > div.modal_title {
    font-size: 18px; }
  .dialog_client.quantity > div.body input {
    box-sizing: border-box;
    margin: 0 11px;
    height: 32px;
    padding: 0 4px;
    font-family: "RobotoMedium", sans-serif;
    font-size: 14px;
    text-align: center;
    border: 1.5px solid rgba(48, 49, 51, 0.15);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    transition: 0.3s;
    width: 50px;
    border-radius: 4px !important; }
    .dialog_client.quantity > div.body input:focus {
      border-color: rgba(48, 49, 51, 0.3); }
  .dialog_client.quantity > div.footer {
    font-size: 14px; }
    .dialog_client.quantity > div.footer img {
      width: 16px;
      height: 16px;
      margin-right: 6px; }
    .dialog_client.quantity > div.footer span {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #4a5568; }
  .dialog_client.quantity > div.button_container .default_button_wrapper {
    width: 100%; }
  .dialog_client.are_you_sure > p {
    margin-bottom: 20px; }

.registration-progress.Mui-disabled:hover {
  background-color: #1cbb32 !important; }

.registration-progress .MuiCircularProgress-colorSecondary {
  color: #ffffff;
  position: absolute;
  left: 20px;
  top: 6px; }

.advertising_channel {
  margin-bottom: 24px; }

.change_discount_wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .change_discount_wrapper input {
    background: #ffffff;
    box-shadow: 0px -1px 2px rgba(48, 49, 51, 0.04), 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    height: 44px;
    width: 56px;
    line-height: 44px;
    text-align: center;
    margin: auto 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px; }

@media only screen and (max-width: 1279px) {
  .client_page {
    padding: 20px 0;
    box-sizing: border-box;
    height: 100%; }
  .dialog_client {
    padding: 40px 30px 0; }
    .dialog_client > div {
      font-size: 25px; }
    .dialog_client > img {
      margin-bottom: 25px; } }

.advantages-page .title_page {
  padding-bottom: 20px; }

.advantages-page .advantages-content {
  display: flex;
  flex-wrap: wrap; }
  .advantages-page .advantages-content .advantage-block {
    padding: 20px 18px 32px;
    margin-right: 15px;
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(48, 49, 51, 0.1);
    border-radius: 8px; }
    @media only screen and (max-width: 1199.9px) {
      .advantages-page .advantages-content .advantage-block {
        width: calc(33.333% - 55px); } }
    @media only screen and (min-width: 1200px) {
      .advantages-page .advantages-content .advantage-block {
        width: calc(25% - 55px); } }
    @media only screen and (min-width: 1440px) {
      .advantages-page .advantages-content .advantage-block {
        width: calc(20% - 55px); } }
    .advantages-page .advantages-content .advantage-block > svg {
      margin-bottom: 16px; }
    .advantages-page .advantages-content .advantage-block > .advantage-text {
      font-weight: 500;
      font-size: 14px;
      color: #2d3d45; }

.camera-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .camera-page .camera-error {
    color: red;
    margin-top: 15px; }

.auth-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }

.auth_content {
  width: auto;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center; }
  .auth_content form {
    width: 460px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 64px 40px;
    position: relative; }

.res_pas_ok {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px; }

.logo_block_auth {
  display: flex;
  justify-content: center;
  padding-bottom: 36px; }

.title_auth {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  padding-bottom: 36px; }

.descriptions_auth {
  margin-top: -24px;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #4A5568;
  padding-bottom: 36px;
  text-align: center; }

.back_block {
  margin-bottom: 73px; }
  .back_block a {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #2D3D45; }
    .back_block a img {
      margin-right: 11px; }

.password_reset {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-bottom: 40px; }
  .password_reset a {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2D3D45; }

.auth_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .auth_bg img {
    background-size: cover;
    left: 0;
    position: absolute;
    background-position: center center;
    top: 0;
    width: 100%;
    height: 100%; }

.auth_btn_wrapper {
  display: flex; }
  .auth_btn_wrapper .default_button_wrapper {
    width: 100%; }
    .auth_btn_wrapper .default_button_wrapper .default_button {
      width: 100%; }

@media only screen and (max-width: 1279px) {
  .auth_content form {
    padding: 35px; }
  .logo_block_auth {
    padding-bottom: 20px; }
    .logo_block_auth img {
      background-size: 100% 100%;
      width: 200px; }
  .title_auth {
    padding-bottom: 25px;
    font-size: 25px;
    line-height: 28px; }
  .descriptions_auth {
    margin-top: -15px;
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 30px;
    flex-flow: column; }
  .password_reset {
    margin-bottom: 20px; }
  .back_block {
    margin-bottom: 40px; }
  .res_pas_ok img {
    background-size: 100% 100%;
    width: 100px;
    height: 100px; } }

